import LogoBeemiSymbol from '@/assets/icons/logo/Logo_BEEMI_sym.svg';
import DesktopHeader from '@/shared/components/Layout/DesktopHeader';
import { errorMessages } from '@/shared/constants/messages';
import { NotFoundContext } from '@/shared/NotFoundContext';
import { FC, useContext } from 'react';
import { useIntl } from 'react-intl';
import Buttons from './Buttons';

const Error404: FC = () => {
  // for letting SSR renderer know if a 404 error is reported, so that it can change response status
  const { setNotFound } = useContext(NotFoundContext);
  if (setNotFound) setNotFound();

  const intl = useIntl();
  return (
    <div>
      <DesktopHeader />
      <div className="flex flex-col items-center w-full h-full px-5 space-y-8 md:px-12 pt-30 pb-14">
        <LogoBeemiSymbol className="w-24 h-24 md:w-30 md:h-30" />
        <div className="flex flex-col items-center text-center text-purple/80">
          <h1 className="mt-8 text-headline">
            {intl.formatMessage(errorMessages.pageNotFound)}
          </h1>
          <p className="mt-2 text-body">
            {intl.formatMessage(
              errorMessages[404],
              // eslint-disable-next-line react/display-name
              { p: (...chunks) => <p>{chunks}</p>, br: <br /> }
            )}
          </p>
        </div>
        <Buttons />
      </div>
    </div>
  );
};

export default Error404;
